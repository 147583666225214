import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  DataGrid,
} from '@material-ui/data-grid';
import { AuthContext } from '../../../../Auth';
import { loadAuthToken } from '../../../../utils/auth';
import Button from '../../../../components/CustomButtons/Button';
import Dialog from '../../../../components/Alert/alert';

import PollQuestionsModal from './ViewPollModal';

export default function PollGrid(props) {
  const {
    data,
  } = props;

  const [pollQuestions, setPollQuestions] = useState({});
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newHeaders, setNewHeaders] = useState([]);
  const [filteredData, setFilteredData] = useState(data);

  const context = useContext(AuthContext);


  const headers = [
    {
      headerName: 'Title', field: 'title', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Description', field: 'description', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Date Created', field: 'createdAt', width: 250, disableClickEventBubbling: true,
    },
  ];

  const archivePoll = (poll) => {
    axios({
      method: 'patch',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/poll/${poll.id}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: {
        status: 'archived',
      },
    }).then(() => {
      context.setMessageState('success');
      context.setSnackBarMessage(`Successfully archived poll: ${poll.title}`);
      setFilteredData(filteredData.filter((el) => el.id !== poll.id));
      setSelectedRowId({});
      // return this.getQuestions();
    }).catch((e) => {
      context.setMessageState('error');
      context.setSnackBarMessage(`${e.response.data.message}`);
    });
  };

  useEffect(() => {
    const headersWithAddedButton = [
      {
        headerName: 'Actions',
        field: 'id',
        width: 325,
        disableClickEventBubbling: true,
        renderCell: (poll) => (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setSelectedRowId(poll.row);
                setIsDialogOpen(!isDialogOpen);
              }}
            >
              Archive
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setPollQuestions(poll.row);
                setIsQuestionModalOpen(!isQuestionModalOpen);
              }}
            >
              View
            </Button>
          </div>
        ),
      },
      ...headers];
    setFilteredData(data);
    setNewHeaders(headersWithAddedButton);
  }, [data]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ height: 600, width: '100%' }}>
      <PollQuestionsModal
        open={isQuestionModalOpen}
        toggleModal={() => {
          setIsQuestionModalOpen(!isQuestionModalOpen);
        }}
        questions={pollQuestions}
      />
      <Dialog
        open={isDialogOpen}
        toggleDialog={setIsDialogOpen}
        text={`Are you sure you want to archive ${selectedRowId.title ? selectedRowId.title : ''}`}
        rowId={selectedRowId.id ? selectedRowId.id : ''}
        action={() => archivePoll(selectedRowId)}
      />
      <DataGrid
        rows={filteredData}
        columns={newHeaders}
        sortingOrder={['asc', 'desc']}
        sortModel={[
          {
            field: 'title',
            sort: 'asc',
          },
        ]}
      />
    </div>
  );
}

PollGrid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object),
};
