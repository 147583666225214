import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../components/Card/Card';
import CardFooter from '../../components/Card/CardFooter';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import styles from '../../assets/jss/material-dashboard-pro-react/views/reportPageStyle';
import SessionsGrid from './SessionsGridNew';
import { AuthContext } from '../../Auth';
import { loadAuthToken } from '../../utils/auth';
import SessionForm from './SessionForm2';

const useStyles = makeStyles(styles);

function SessionsTable() {
  const classes = useStyles();
  const context = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { user, setMessageState, setSnackBarMessage } = context;
  const history = useHistory();

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_EVENT_SERVICE}/session?completed=false&userId=${user.id}`,
        headers: {
          Authorization: `bearer ${loadAuthToken()}`,
        },
      }).then((res) => {
        setData(
          res.data.map((session) => ({
            id: session.id,
            title: session.title,
            session: session.id,
            pin: session.pin,
            type: session.type,
            company: session.company.name,
            audienceUsers: session.audienceUsers,
            started: session.started,
          })),
        );
        setIsLoading(false);
        return res;
      })
        .catch((e) => {
          console.log(e);
          setMessageState('error');
          setSnackBarMessage(`${e.response.data.message}`);
        });
    };
    fetchData();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const findSessions = async () => {
    await axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/session?completed=false&userId=${user.id}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).catch((err) => {
      try {
        setMessageState('error');
        setSnackBarMessage(`${err.response.data.message}`);
      } catch (e) {
        setMessageState('error');
        setSnackBarMessage(e.message);
      }
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        history.push('auth/login');
      }
      return { data: [] };
    }).then((res) => {
      setData(
        res.data.map((session) => ({
          id: session.id,
          title: session.title,
          session: session.id,
          pin: session.pin,
          type: session.type,
          company: session.company.name,
          audienceUsers: session.audienceUsers,
          started: session.started,
        })),
      );
    });
    if (data.error === 'your token is not valid' || data.error === 'Your token is expired') {
      context.sendToLogin();
    }
  };

  return (
    <Card style={{ minHeight: '80vh' }}>
      <SessionForm findSessions={findSessions} />
      <CardHeader className={classes.headerAlign}>
        <div className={classes.header}>
          <h3>Sessions</h3>
        </div>

      </CardHeader>
      <CardBody className={classes.reportCardBody}>
        {isLoading ? (
          <div style={{ margin: 'auto' }}>
            <PulseLoader
              sizeUnit="px"
              size={15}
              color="#6CCA98"
              loading
            />
          </div>
        ) : <SessionsGrid data={data} findSessions={findSessions} />}
      </CardBody>

      <CardFooter stats>
        <div className={classes.stats}>
          <p className={classes.cardTitle}>
            Total:
            {data.length}
          </p>
        </div>
      </CardFooter>
    </Card>
  );
}

export default SessionsTable;
