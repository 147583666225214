import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  DataGrid,
} from '@material-ui/data-grid';
import Cookies from 'universal-cookie';

import Button from '../../components/CustomButtons/Button';
import HostForm from './HostForm';
// import SendInviteDialog from './SendInviteDialog';
import EndSessionDialog from './EndSessionDialog';

import { AuthContext } from '../../Auth';
import { loadAuthToken } from '../../utils/auth';

import './sessionTable.css';

const cookies = new Cookies();

export default function SessionsGrid(props) {
  const [newHeaders, setNewHeaders] = useState([]);
  const [startedSessionId, setStartedSessionId] = useState(-1);
  const [hostFormOpen, setHostFormOpen] = useState(false);
  const [endSessionDialogOpen, setEndSessionDialogOpen] = useState(false);
  const [sessionToEnd, setSessionToEnd] = useState(-1);
  const [pinNumber, setPinNumber] = useState(0);
  // const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  // const [invitedSessionId, setInvitedSessionId] = useState(-1);
  // const [invitedPin, setInvitedPin] = useState(-1);

  const context = useContext(AuthContext);
  const history = useHistory();

  const {
    data, findSessions,
  } = props;

  const headers = [
    {
      headerName: 'Title', field: 'title', width: 200, disableClickEventBubbling: true,
    },
    {
      headerName: 'Company', field: 'company', width: 200, disableClickEventBubbling: true,
    },
    {
      headerName: 'Pin', field: 'pin', width: 100, disableClickEventBubbling: true,
    },
    {
      headerName: 'Platform', field: 'type', width: 125, disableClickEventBubbling: true,
    },

  ];

  const startSession = (id, isHosted) => axios({
    method: 'patch',
    url: `${process.env.REACT_APP_EVENT_SERVICE}/session/${id}`,
    data: {
      started: true,
      startedAt: Date.now(),
    },
    headers: {
      Authorization: `bearer ${loadAuthToken()}`,
    },
  }).then(() => {
    if (isHosted) {
      setStartedSessionId(id);
      setHostFormOpen(true);
      context.setMessageState('success');
      context.setSnackBarMessage('Session successfully started!');
    } else {
      findSessions();
    }
  })
    .catch((err) => {
      context.setMessageState('error');
      context.setSnackBarMessage(`${err.response.data.message}`);
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        history.push('auth/login');
      }
    });

  const isAHostedSessionStarted = () => data.some((session) => session.started && (session.type === 'hosted' || 'autohosted'));

  useEffect(() => {
    const d = new Date();
    d.setTime(d.getTime() + (2 * 24 * 60 * 60 * 1000));
    const headersWithAddedButton = [
      {
        headerName: 'Actions',
        field: `id${1}`,
        width: 250,
        disableClickEventBubbling: true,
        // eslint-disable-next-line consistent-return
        renderCell: (session) => {
          // console.log('session', session);
          if (session.row.started && session.row.type === 'autohosted') {
            return (
              <>
                <div>
                  <Button
                    variant="contained"
                    className="button"
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      cookies.set('sessionId', session.row.id, { expires: d, domain: process.env.REACT_APP_DOMAIN, path: '/' });
                      cookies.set('user', context.user, { expires: d, domain: process.env.REACT_APP_DOMAIN, path: '/' });
                      cookies.set('slideIndex', 0, { expires: d, domain: process.env.REACT_APP_DOMAIN, path: '/' });
                      window.location.replace('https://autohost.join.host');
                    }}
                  >
                    Go To Host
                  </Button>
                  {/* </div>
                <div> */}
                  <Button
                    className="button"
                    disabled={!isAHostedSessionStarted(session.row)}
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      setHostFormOpen(true);
                      setStartedSessionId(session.row.id);
                      setPinNumber(session.row.pin);
                    }}
                  >
                    Add Phone #&apos;s
                  </Button>

                  <Button
                    onClick={(e) => {
                      // console.log(endSessionDialogOpen);
                      setSessionToEnd(session.row.id);
                      e.preventDefault();
                      setEndSessionDialogOpen(true);
                    }}
                    className="button"
                  >
                    End Session
                  </Button>
                </div>
              </>
            );
          }
          if (!session.row.started && session.row.type === 'autohosted') {
            return (
              <div>
                <Button
                  className="button"
                  disabled={isAHostedSessionStarted()}
                  onClick={() => {
                    startSession(session.row.id, true);
                    setPinNumber(session.row.pin);
                  }}
                >
                  Start Session
                </Button>
                <Button
                  onClick={(e) => {
                    setSessionToEnd(session.row.id);
                    e.preventDefault();
                    setEndSessionDialogOpen(true);
                  }}
                  className="button"
                >
                  End Session
                </Button>

              </div>
            );
          }
          if (session.row.started && session.row.type === 'hosted') {
            return (
              <div>
                <Button
                  className="button"
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push('/host/host');
                  }}
                >
                  Go To Host
                </Button>
                <Button
                  className="button"
                  disabled={!isAHostedSessionStarted(session.row)}
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    setHostFormOpen(true);
                    setStartedSessionId(session.row.id);
                    setPinNumber(session.row.pin);
                  }}
                >
                  Add Phone #&apos;s
                </Button>
                <Button
                  onClick={(e) => {
                    setSessionToEnd(session.row.id);
                    e.preventDefault();
                    setEndSessionDialogOpen(true);
                  }}
                  className="button"
                >
                  End Session
                </Button>
              </div>
            );
          }
          if (!session.row.started && session.row.type === 'hosted') {
            return (
              <div>
                <Button
                  className="button"
                  disabled={isAHostedSessionStarted()}
                  onClick={() => {
                    startSession(session.row.id, true);
                    setPinNumber(session.row.pin);
                  }}
                >
                  Start Session
                </Button>
                <Button
                  onClick={(e) => {
                    setSessionToEnd(session.row.id);
                    e.preventDefault();
                    setEndSessionDialogOpen(true);
                  }}
                  className="button"
                >
                  End Session
                </Button>

              </div>
            );
          }
        },
      },
      // {
      //   headerName: 'End Session',
      //   field: 'id',
      //   width: 175,
      //   disableClickEventBubbling: true,
      //   renderCell: (session) => {
      //     // if (session.row) {
      //     //   return (
      //     //     <div>
      //     //       <Button onClick={(e) => {
      //     //         // console.log(endSessionDialogOpen);
      //     //         setSessionToEnd(session.row.id);
      //     //         e.preventDefault();
      //     //         setEndSessionDialogOpen(true);
      //     //       }}
      //     //       >
      //     //         End Session
      //     //       </Button>
      //     //     </div>
      //     //   );
      //     // }
      //   },
      // },

      ...headers];
    setNewHeaders(headersWithAddedButton);
  }, [data]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ height: 600, width: '100%' }}>
      <HostForm
        open={hostFormOpen}
        sessionId={startedSessionId}
        toggleModal={() => {
          setHostFormOpen(false);
          findSessions();
        }}
        pin={pinNumber}
      />
      <EndSessionDialog
        toggleModal={() => {
          setEndSessionDialogOpen(!endSessionDialogOpen);
          findSessions();
        }}
        sessionId={sessionToEnd}
        open={endSessionDialogOpen}
        setSnackBarMessage={context.setSnackBarMessage}
        setMessageState={context.setMessageState}
        // sessionId={invitedSessionId}
        // pin={invitedPin}

      />
      <DataGrid
        rows={data}
        columns={newHeaders}
        // sortingOrder={['asc', 'desc']}
        // sortModel={[
        //   {
        //     field: 'title',
        //     sort: 'asc',
        //   },
        // ]}
      />
    </div>
  );
}

SessionsGrid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object),
  findSessions: PropTypes.func,
};
