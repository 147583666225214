/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import LiveChat from './LiveChat/LiveChatFunc';
import InfoModule from './Modules/InfoModule';
import Wop from './Modules/WalkOfPrivilege/WalkOfPrivilege';
import HostedDefault from './Modules/HostedDefault';
import SignUp from './Modules/SignUp';
import Survey from './Modules/Survey/Survey';
import SentimentSurvey from './Modules/SentimentSurvey';
import KeyWordSurvey from './Modules/KeyWordSurvey';
import WordCloud from './Modules/WordCloud/WordCloud';
import Comfort from './Modules/Comfort/Comfort';
import Spectrum from './Modules/Spectrum/Spectrum';
import Poll from './Modules/Poll/NewPoll';
import { HostLayoutContext } from '../../layouts/Host';
import { AuthContext } from '../../Auth';
import WordPopcorn from './Modules/WordPopcorn/WordPopcorn';

function compare(a, b) {
  const bandA = a.order;
  const bandB = b.order;
  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
}

const HostedAudience = ({ session, removeSession }) => {
  const [currentModule, setCurrentModule] = useState({ moduleType: 'default' });
  const [moduleData, setModuleData] = useState({});
  const [wopQuestions, setWopQuestions] = useState([]);
  const [surveyQuestions, setSurveyQuestions] = useState([]);
  const [sentimentSurveyQuestions, setSentimentSurveyQuestions] = useState([]);
  const [keyWordSurveyQuestions, setKeyWordSurveyQuestions] = useState([]);
  const [comfortQuestions, setComfortQuestions] = useState([]);
  const [wordCloudQuestion, setWordCloudQuestion] = useState([]);
  const [spectrumQuestions, setSpectrumQuestions] = useState([]);
  const [averageComfortAnswers, setAverageComfortAnswers] = useState([]);
  const [wordCloudIcon, setWordCloudIcon] = useState('');
  const [infoModuleText, setInfoModuleText] = useState('<div />');
  const [pollQuestions, setPollQuestions] = useState([]);
  const [pollScript, setPollScript] = useState('');
  // const [chatToggle, setChatToggle] = useState(false);
  const { setIsWop } = useContext(HostLayoutContext);
  const context = useContext(AuthContext);
  const { pusher } = context;

  const setModule = async (res) => {

    if (res.data) {
      const currentMod = res.data[0];
      setIsWop(false);
      setCurrentModule({ moduleType: currentMod.moduleType });
    } else {
      try {
        switch (res.currentModule.moduleType) {
          case 'walkOfPrivilege': {
            setIsWop(true);
            await setWopQuestions(res.moduleData.wop_question_joins);
            break;
          }
          case 'infoModule': {
            setIsWop(false);
            setInfoModuleText(res.moduleData.text);
            break;
          }
          case 'survey': {
            setIsWop(false);
            setSurveyQuestions(res.moduleData.survey_question_joins
              .sort(compare)
              .map((questionObj) => questionObj.survey_question));
            break;
          }
          case 'sentimentSurvey': {
            setIsWop(false);
            setSentimentSurveyQuestions(res.moduleData.sentiment_survey_joins
              .sort(compare)
              .map((questionObj) => questionObj.sentiment_survey_question));
            break;
          }
          case 'keyWordSurvey': {
            setIsWop(false);
            setKeyWordSurveyQuestions(res.moduleData.key_word_survey_joins
              .sort(compare)
              .map((questionObj) => questionObj.key_word_survey_question));
            break;
          }
          case 'wordCloud': {
            setIsWop(false);
            setWordCloudIcon(res.moduleData.icon);
            setWordCloudQuestion(res.moduleData.cloud_question_joins
              .sort(compare)
              .map((questionObj) => questionObj.word_cloud_question));
            break;
          }
          case 'comfort': {
            setIsWop(false);
            setComfortQuestions(res.moduleData.comfort_meter_joins
              .sort(compare)
              .map((questionObj) => questionObj.comfort_meter_question));
            break;
          }
          case 'spectrum': {
            setIsWop(false);
            setSpectrumQuestions(res.moduleData.spectrum_question_joins
              .sort(compare)
              .map((questionObj) => questionObj.spectrum_question));
            break;
          }
          case 'poll': {
            setIsWop(false);
            setPollScript(res.moduleData.script)
            setPollQuestions(res.moduleData.poll_question_joins
              .sort(compare)
              .map((questionObj) => questionObj.poll_question));
            break;
          }
          case 'wordPopcorn': {
            setIsWop(false);
            setModuleData(res.moduleData);
            break;
          }
          default: {
            setIsWop(false);
            break;
          }
        }
      } catch (e) {
        console.error(e);
      }
      setCurrentModule(res.currentModule);
    }
  };

  const findCurrentModule = () => axios({
    method: 'get',
    url: `${process.env.REACT_APP_EVENT_SERVICE}/current-module?sessionId=${session.id}`,
  })
    .then((res) => {
      // module data is only used in word popcorn.  Might need an if
      setModuleData(res.data.moduleData);
      // Only updating the module data for audience when the host screen changes to an activity
      // aka not when host is on an auto host slide
      if (res.data.currentModule.moduleType !== 'autoHostSlide') {
        
        setModule(res.data);
      }
    })
    .catch((err) => {
      console.error(err);
    });

  useEffect(() => {
    // https://developers.google.com/web/updates/2018/07/page-lifecycle-api#event-pageshow
    // document.addEventListener(visibilityChange, () => {
    //   if (document.visibilityState === 'visible') findCurrentModule();
    // });
    document.addEventListener('visibilitychange', findCurrentModule);

    window.addEventListener('focus', findCurrentModule);
    document.addEventListener('focus', findCurrentModule);

    window.addEventListener('resume', findCurrentModule);
    document.addEventListener('resume', findCurrentModule);
    return () => {
      // document.removeEventListener(visibilityChange, findCurrentModule);
      document.removeEventListener('visibilitychange', findCurrentModule);
      window.removeEventListener('focus', findCurrentModule);
      document.removeEventListener('focus', findCurrentModule);
      window.removeEventListener('resume', findCurrentModule);
      document.removeEventListener('resume', findCurrentModule);
    };
  }, []);

  useEffect(() => {
    findCurrentModule();
    // if (inviteData !== '') context.eventsClient.service('invite').patch({ query: inviteData });
    const channel = pusher.subscribe(`${session.id}`);
    channel.bind('current-module-change', (res) => {
      if (res.currentModule.moduleType === 'walkOfPrivilege') {
        setModule(res);
        axios({
          method: 'get',
          url: `${process.env.REACT_APP_EVENT_SERVICE}/get-walk-of-privilege-questions?moduleId=${res.currentModule.moduleId}`,
        }).then((_res) => {
          setWopQuestions(_res.data);
        });
      } else if (res.currentModule.moduleType === 'comfort') {
        findCurrentModule();
      } else {
        setModule(res);
      }


    });
    channel.bind('session-end', () => removeSession());
    channel.bind('audience-comfort-answer', (data) => setAverageComfortAnswers(data.questionAndPoints));
    return () => {
      channel.unbind('session-end');
      channel.unbind('current-module-change');
    };
  }, []);

  const renderModule = () => {
    switch (currentModule.moduleType) {
      case 'walkOfPrivilege': {
        return (
          <Wop
            moduleTitle={currentModule.moduleTitle}
            moduleId={currentModule.moduleId}
            setCorrectBackground={() => setIsWop(false)}
            sessionId={session.id}
            isHosted
            rawQuestions={wopQuestions}
          />
        );
      }
      case 'infoModule': {
        return (
          <InfoModule
            sessionId={session.id}
            isHosted
            moduleTitle={currentModule.moduleTitle}
            text={infoModuleText}
          />
        );
      }
      case 'signUp': {
        return (
          <SignUp
            sessionId={session.id}
            isHosted
            moduleTitle={currentModule.moduleTitle}
            text={infoModuleText}
          />
        );
      }
      case 'survey': {
        return (
          <Survey
            isHosted
            isSentiment={false}
            moduleTitle={currentModule.moduleTitle}
            moduleId={currentModule.moduleId}
            questions={surveyQuestions}
            sessionId={session.id}
          />
        );
      }
      case 'sentimentSurvey': {
        return (
          <SentimentSurvey
            isHosted
            moduleTitle={currentModule.moduleTitle}
            moduleId={currentModule.moduleId}
            questions={sentimentSurveyQuestions}
            sessionId={session.id}
          />
        );
      }
      case 'keyWordSurvey': {
        return (
          <KeyWordSurvey
            isHosted
            moduleTitle={currentModule.moduleTitle}
            moduleId={currentModule.moduleId}
            questions={keyWordSurveyQuestions}
            sessionId={session.id}
          />
        );
      }
      case 'wordCloud': {
        return (
          <WordCloud
            isHosted
            wordCloudIcon={wordCloudIcon}
            moduleTitle={currentModule.moduleTitle}
            moduleId={currentModule.moduleId}
            sessionId={session.id}
            questions={wordCloudQuestion}
          />
        );
      }
      case 'comfort': {
        return (
          <Comfort
            isHosted
            averageComfortAnswers={averageComfortAnswers}
            moduleTitle={currentModule.moduleTitle}
            moduleId={currentModule.moduleId}
            sessionId={session.id}
            questions={comfortQuestions}
          />
        );
      }
      case 'spectrum': {
        return (
          <Spectrum
            isHosted
            moduleTitle={currentModule.moduleTitle}
            moduleId={currentModule.moduleId}
            sessionId={session.id}
            questions={spectrumQuestions}
          />
        );
      }
      case 'poll': {
        return (
          <Poll
            isHosted
            moduleTitle={currentModule.moduleTitle}
            moduleId={currentModule.moduleId}
            sessionId={session.id}
            questions={pollQuestions}
            pollScript={pollScript}
          />
        );
      }
      case 'wordPopcorn': {
        return (
          <WordPopcorn
            isHosted
            moduleTitle={currentModule.moduleTitle}
            moduleId={currentModule.moduleId}
            sessionId={session.id}
            moduleData={moduleData}
          />
        );
      }
      default: {
        return <HostedDefault pin={session.pin}/>;
      }
    }
  };

  return (
    <div style={{ height: '100%', width: '97%' }}>
      {renderModule()}
      <LiveChat sessionId={session.id} moduleData={currentModule}/>
    </div>
  );
};

HostedAudience.propTypes = {
  session: PropTypes.object.isRequired,
  // inviteData: PropTypes.string.isRequired,
  removeSession: PropTypes.func.isRequired,
};

export default HostedAudience;
