import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  DataGrid,
} from '@material-ui/data-grid';
import { AuthContext } from '../../../../Auth';
import { loadAuthToken } from '../../../../utils/auth';
import Button from '../../../../components/CustomButtons/Button';
import Dialog from '../../../../components/Alert/alert';

// import PollQuestionsModal from './ViewPollQuestionsModal';

export default function PollGrid(props) {
  const {
    data,
  } = props;

  //   const [pollQuestions, setPollQuestions] = useState({});
  //   const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newHeaders, setNewHeaders] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
 

  const context = useContext(AuthContext);

  const headers = [
    {
      headerName: 'Question', field: 'question', width: 300, disableClickEventBubbling: true,
    },
    {
      headerName: 'Type', field: 'type', width: 150, disableClickEventBubbling: true,
    },
    {
      headerName: 'Options', field: 'options', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Chart Type', field: 'chartType', width: 150, disableClickEventBubbling: true,
    },
  ];

  const archivePoll = (pollQuestion) => {
    axios({
      method: 'patch',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/poll-question/${pollQuestion.id}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: {
        status: 'archived',
      },
    }).then(() => {
      context.setMessageState('success');
      context.setSnackBarMessage(`Successfully archived poll question: ${pollQuestion.question}`);
      setFilteredData(filteredData.filter((el) => el.id !== pollQuestion.id));
      setSelectedRowId({});
      // return this.getQuestions();
    }).catch((e) => {
      context.setMessageState('error');
      context.setSnackBarMessage(`${e.response.data.message}`);
    });
  };

  useEffect(() => {
    const headersWithAddedButton = [
      {
        headerName: 'Actions',
        field: 'id',
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (poll) => (
          <div>
            <Button
            //   disabled
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setSelectedRowId(poll.row);
                setIsDialogOpen(!isDialogOpen);
              }}
            >
              Archive
            </Button>
          </div>
        ),
      },
      ...headers];
    setFilteredData(data);
    setNewHeaders(headersWithAddedButton);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div style={{ height: 600, width: '100%' }}>
      {/* <PollQuestionsModal
        open={isQuestionModalOpen}
        toggleModal={() => {
          setIsQuestionModalOpen(!isQuestionModalOpen);
        }}
        questions={pollQuestions}
      /> */}
      
      <Dialog
        open={isDialogOpen}
        toggleDialog={setIsDialogOpen}
        text={`Are you sure you want to archive ${selectedRowId.question ? selectedRowId.question : ''}`}
        rowId={selectedRowId.id ? selectedRowId.id : ''}
        action={() => archivePoll(selectedRowId)}
      />
      
      <DataGrid
        rows={filteredData}
        columns={newHeaders}
        sortingOrder={['asc', 'desc']}
        sortModel={[
          {
            field: 'question',
            sort: 'asc',
          },
        ]}
      />
    </div>
  );
}

PollGrid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object),
};
