import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { PulseLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../../../components/Card/Card';
import CardFooter from '../../../../components/Card/CardFooter';
import CardBody from '../../../../components/Card/CardBody';
import CardHeader from '../../../../components/Card/CardHeader';
import Button from '../../../../components/CustomButtons/Button';
import styles from '../../../../assets/jss/material-dashboard-pro-react/views/reportPageStyle';
import PollQuestionsGrid from './PollQuestionsGrid';
import NewQuestionModal from '../CreateNewPollQuestion';
import { AuthContext } from '../../../../Auth';
import { loadAuthToken } from '../../../../utils/auth';


const useStyles = makeStyles(styles);

function PollQuestionsTable() {
  const classes = useStyles();
  const context = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isNewQuestionModalOpen, setIsNewQuestionModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(!isLoading);
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_MODULE_SERVICE}/poll-question?status=active`,
        headers: {
          Authorization: `bearer ${loadAuthToken()}`,
        },
      }).then((res) => {
        setData(res.data.data.map((pollQuestion) => ({
          question: pollQuestion.question,
          type: pollQuestion.type,
          options: pollQuestion.options.options ? pollQuestion.options.options.map((options) => options.option) : '',
          id: pollQuestion.id,
          chartType: pollQuestion.chartType,
        })));
        setIsLoading(false);
      })
        .catch((e) => {
          console.log(e);
          context.setMessageState('error');
          context.setSnackBarMessage(`${e.response.data.message}`);
        });
    };

    fetchData();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <NewQuestionModal
        togalModal={() => setIsNewQuestionModalOpen(!isNewQuestionModalOpen)}
        open={isNewQuestionModalOpen}
      />
      <Card>
        <CardHeader className={classes.headerAlign}>
          <div className={classes.header}>
            <h3>Poll Questions</h3>
            <Button
              onClick={() => setIsNewQuestionModalOpen(!isNewQuestionModalOpen)}
            >
              Create New Question
            </Button>
          </div>

        </CardHeader>
        <CardBody className={classes.reportCardBody}>
          {isLoading ? (
            <PulseLoader
              sizeUnit="px"
              size={15}
              color="#6CCA98"
              loading
            />
          ) : <PollQuestionsGrid data={data} />}

        </CardBody>

        <CardFooter stats>
          <div className={classes.stats}>
            <p className={classes.cardTitle}>
              Total:
              {data.length}
            </p>
          </div>
        </CardFooter>
      </Card>
    </>
  );
}

export default PollQuestionsTable;
