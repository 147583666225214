/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';

// @material-ui/icons
import ViewModuleIcon from '@material-ui/icons/ViewModule';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
// core components
import { FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import CustomInput from '../../../components/CustomInput/CustomInput';
import customSelectStyle from '../../../assets/jss/material-dashboard-pro-react/customSelectStyle';
import customListStyle from '../../../assets/jss/material-dashboard-pro-react/customListStyle';
import customCheckboxRadioSwitch from '../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch';
import { AuthContext } from '../../../Auth';

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center',
  },
  inputAdornmentIcon: {
    color: '#555',
  },
  inputAdornment: {
    position: 'relative',
  },
  ...customListStyle,
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
};

class Step1 extends React.Component {
    // eslint-disable-next-line react/static-property-placement
    static contextType = AuthContext;

    constructor(props) {
      super(props);
      this.state = {
        nameState: '',
        descriptionState: '',
        dropDownState: '',
        clients: [],
        isDemo: false,
        hostedType: 'autohosted',
        clientId: -1,
        selectedClientTitle: 'Please Select a Client',
        description: '',
      };
    }

    componentDidMount() {
      this.context.authClient.reAuthenticate().then(() => {
        this.context.authClient.service('companies').find().then((res) => {
          this.setState({ clients: res.data });
        })
          .catch((err) => {
            this.context.setMessageState('error');
            this.context.setSnackBarMessage(`${err.response.data.message}`);
            if (err.error === 'your token is not valid' || err.error === 'Your token is expired' || err.error === 'No accessToken found in storage') {
              this.context.sendToLogin();
            }
          });
      });
    }

    handleChange= (event) => {
      this.setState({ hostedType: event.target.value });
    }

    change(event, stateName, type, stateNameEqualTo) {
      switch (type) {
        case 'length':
          if (validator.isLength(event.target.value, stateNameEqualTo)) {
            this.setState({ [`${stateName}State`]: 'success' });
          } else {
            this.setState({ [`${stateName}State`]: 'error' });
          }
          break;
        default:
          break;
      }
      this.setState({ [stateName]: event.target.value });
    }

    isValidated() {
      const { nameState, descriptionState, clientId } = this.state;
      if (
        nameState === 'success' && descriptionState === 'success' && clientId > 0
      ) {
        return true;
      }
      if (nameState !== 'success') {
        this.setState({ nameState: 'error' });
      }
      if (descriptionState !== 'success') {
        this.setState({ descriptionState: 'error' });
      }
      if (clientId <= 0) {
        this.setState({ dropDownState: 'error' });
      }

      return false;
    }

    sendState() {
      return this.state;
    }

    render() {
      const { classes } = this.props;
      const {
        nameState, isDemo, descriptionState, dropDownState,
      } = this.state;
      return (
        <GridContainer justifyContent="center">

          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>
              Let &apos;s start with the basic information about your Event
            </h4>
          </GridItem>
          <GridItem xs={6} sm={3}>
            <p>Is it hosted?</p>
            <RadioGroup name="type" value={this.state.hostedType} onChange={this.handleChange}>
              <FormControlLabel value="autohosted" control={<Radio />} label="autohosted" />
              <FormControlLabel value="hosted" control={<Radio />} label="hosted" />
            </RadioGroup>
          </GridItem>
          <GridItem xs={8} sm={3}>
            <div>
              <p>
                Is this a demo event?
              </p>
              <Switch
                onChange={() => this.setState({ isDemo: !isDemo })}
              />
              <p><bold>{isDemo ? ' Yes' : ' No'}</bold></p>
            </div>
          </GridItem>
          <GridItem xs={12} sm={10}>
            <CustomInput
              success={nameState === 'success'}
              error={nameState === 'error'}
              labelText={(
                <span>
                  Name of the event/session?
                  {' '}
                  <small>(required)</small>
                </span>
            )}
              id="name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, 'name', 'length', 3),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <ViewModuleIcon className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />

          </GridItem>
          <GridItem xs={12} sm={10}>
            <CustomInput
              success={descriptionState === 'success'}
              error={descriptionState === 'error'}
              labelText={(
                <span>
                  Description
                  {' '}
                  <small>(required)</small>
                </span>
            )}
              id="description"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, 'description', 'length', 3),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <ViewModuleIcon className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />

          </GridItem>
          <GridItem xs={12} md={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
                required
                error={dropDownState === 'error'}
              >
                Select Client
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                onChange={(e) => this.setState({ clientId: e.target.value })}
                inputProps={{
                  name: 'simpleSelect',
                  id: 'simple-select',
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Select Client (required)
                </MenuItem>
                {this.state.clients.map((client) => (
                  <MenuItem
                    key={client.id}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={client.id}
                  >
                    {client.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
      );
    }
}

Step1.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(style)(Step1);
