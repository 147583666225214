import {
  // eslint-disable-next-line max-len
  Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, MenuItem, Select, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, InputLabel,
} from '@material-ui/core';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import IconButton from '@material-ui/core/IconButton';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AWS from 'aws-sdk';
import CustomInput from '../../../components/CustomInput/CustomInput';
import { AuthContext } from '../../../Auth';
import { loadAuthToken } from '../../../utils/auth';
import Button from '../../../components/CustomButtons/Button';
import customSelectStyle from '../../../assets/jss/material-dashboard-pro-react/customSelectStyle';
import customListStyle from '../../../assets/jss/material-dashboard-pro-react/customListStyle';
import customCheckboxRadioSwitch from '../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';

const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET;
const REGION = 'us-east-1';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const useStyles = makeStyles({
  hiddenInputBox: {
    display: 'none',
  },
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center',
  },
  inputAdornmentIcon: {
    color: '#555',
  },
  inputAdornment: {
    position: 'relative',
  },
  question: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: '15vw',
    textAlign: 'center',
    alignItems: 'center',
  },
  unorderedList: {
    listStyleType: 'none',
  },
  ...customListStyle,
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
});

const NewSlideModal = ({
  open, type, toggleModal, history,
}) => {
  const [title, setTitle] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [description, setDescription] = useState(null);
  const [slideQuestions, setSlideQuestions] = useState([]);
  const [reportType, setReportType] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [generalText, setGeneralText] = useState('');
  const [lang, setLang] =useState('en');
  const [questions, setQuestions] = useState([]);
  const [progress, setProgress] = useState(0);
  // const [link, setLink] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  // const [buttonDisabled, setButtonDisabled] = useState(false);

  const classes = useStyles();
  const context = useContext(AuthContext);

  useEffect(() => {
    setTitle(null);
    setVideoUrl(null);
    setDescription(null);
    setSlideQuestions([]);
    setReportType(null);
    setImageUrl(null);
    setProgress(0);
    setSelectedFile(null);
  }, [toggleModal]);

  useEffect(() => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/slide-question?status=active`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      setQuestions(
        res.data.data.map((question) => ({
          questionText: question.questionText,
          questionId: question.id,
        })),
      );
    }).catch();
  }, []);

  const selectQuestion = (q) => {
    setSlideQuestions([...slideQuestions, {
      id: q.questionId,
      questionText: q.questionText,
    }]);
  };

  const removeAnswer = (index) => {
    const questionsCopy = [...slideQuestions];
    questionsCopy.splice(index, 1);
    setSlideQuestions(questionsCopy);
  };

  // const validate = () => {
  //   if (type === 'welcome') {
  //     console.log('title', title, 'description', description);
  //     if (title && description && videoUrl) {
  //       setButtonDisabled(false);
  //     }
  //   }
  //   if (type === 'video') {
  //     if (title && description && videoUrl) {
  //       setButtonDisabled(false);
  //     }
  //   }
  //   if (type === 'discussion') {
  //     if (title && description && imageUrl && slideQuestions.length) {
  //       setButtonDisabled(false);
  //     }
  //   }
  //   if (type === 'report') {
  //     if (title && description && reportType) {
  //       setButtonDisabled(false);
  //     }
  //   }
  //   if (type === 'closing') {
  //     if (title && description && imageUrl && generalText) {
  //       setButtonDisabled(false);
  //     }
  //   }
  // };

  const handleSubmit = () => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/slide`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: {
        title,
        type,
        description,
        videoUrl,
        reportType,
        questions: slideQuestions,
        imageUrl,
        generalText,
        lang,
      },
    }).then(() => {
      context.setMessageState('success');
      context.setSnackBarMessage(`Successfully created ${type} slide: ${title}`);
      toggleModal();
    }).catch((err) => {
      context.setMessageState('error');
      context.setSnackBarMessage(`${err.response.data.message}`);
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        history.push('auth/login');
      }
    });
  };

  const handleFileInput = (e) => {
    // console.log('handle file input', e.target.files[0]);
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const uploadFile = (file, slideType) => {
    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: file.name,
      ContentType: file.type,
    };
    // console.log('upload params', params);

    myBucket.putObject(params)
      .on('httpUploadProgress', (evt) => {
        // console.log('on upload progress', evt);
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .on('success', (x) => {
        // console.log('on success', x.request.httpRequest, slideType);
        const { path, headers } = x.request.httpRequest;
        const fileString = `https://${headers.Host}${path}`;
        if (slideType === 'video') {
          setVideoUrl(fileString);
        }
        if (slideType === 'discussion') {
          setImageUrl(fileString);
        }
      })
      .send((err) => {
        // console.log(err, 'error');
        if (err) console.log(err, 'err');
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={toggleModal}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: '100%',
            minHeight: '60%',
          },
        }}
      >
        <DialogTitle>
          Create
          {' '}
          {type}
          {' '}
          Slide
        </DialogTitle>
        <DialogContent>
          <div>
            <CustomInput
              // eslint-disable-next-line eqeqeq
              error={!title}
              labelText={(
                <span>
                  Title
                  {' '}
                  <small>(required)</small>
                </span>
          )}
              id="title"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: title,
                onChange: (event) => {
                  setTitle(event.target.value);
                  // validate();
                },
              }}
            />
          </div>
          <div>
            <CustomInput
              error={!description}
              labelText={(
                <span>
                  Description
                  {' '}
                  <small>(required)</small>
                </span>
          )}
              id="description"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: description,
                onChange: (event) => {
                  setDescription(event.target.value);
                  // validate();
                },
              }}
            />
          </div>
          <div>
            <CustomInput
              error={!lang}
              labelText={(
                <span>
                  Language
                  {' '}
                  <small>(required)</small>
                </span>
          )}
              id="lang"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: lang,
                onChange: (event) => {
                  setDescription(event.target.value);
                  // validate();
                },
              }}
            />
          </div>
          <div className={type === 'video' || type === 'welcome' ? null : classes.hiddenInputBox}>
            <CustomInput
              error={!videoUrl}
              labelText={(
                <span>
                  Video Url
                  {' '}
                  <small>(required)</small>
                </span>
          )}
              id="videoUrl"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: videoUrl,
                onChange: (event) => {
                  setVideoUrl(event.target.value);
                  // validate();
                },
              }}
            />
            <GridContainer justifyContent="center" style={{ textAlign: 'center' }}>
              <GridItem xs={12}>
                <strong>OR </strong>
                choose and upload an mp4 file.  Once the file has been uploaded click &apos;Create Slide&lsquo;.
                <h5>
                  File Upload Progress is:
                  {progress}
                  %
                </h5>
              </GridItem>
              <GridItem xs={12}>
                <input type="file" onChange={handleFileInput} />
                <button type="submit" onClick={() => uploadFile(selectedFile, 'video')}> Upload File </button>
              </GridItem>
            </GridContainer>

          </div>
          <div className={type === 'discussion' ? null : classes.hiddenInputBox}>
            <InputLabel>Select Question(s) (required)</InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              onChange={(e) => {
                selectQuestion(e.target.value);
                // validate();
              }}
              inputProps={{
                name: 'simpleSelect',
                id: 'simple-select',
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                Select Question
              </MenuItem>
              {questions.map((question) => (
                <MenuItem
                  key={question.questionId}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  defaultValue="Select Question"
                  value={question}
                >
                  {question.questionText}
                </MenuItem>
              ))}
            </Select>

            <ul className={classes.unorderedList}>
              {slideQuestions.map((question, index) => (
                <div className={classes.question} key={question.questionId}>
                  <IconButton onClick={() => removeAnswer(index)}>
                    <RemoveCircleOutlineRoundedIcon className={classes.iconColor} />
                  </IconButton>
                  <li>{question.questionText}</li>
                </div>
              ))}
            </ul>
          </div>
          <div className={type === 'discussion' || type === 'closing' ? null : classes.hiddenInputBox}>
            <CustomInput
              error={!imageUrl}
              labelText={(
                <span>
                  Image Url
                  {' '}
                  <small>(required)</small>
                </span>
          )}
              id="imageUrl"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: imageUrl,
                onChange: (event) => {
                  setImageUrl(event.target.value);
                  // validate();
                },
              }}
            />
            <GridContainer justifyContent="center" style={{ textAlign: 'center' }}>
              <GridItem xs={12}>
                <strong>OR </strong>
                choose and upload an image file.  Once the file has been uploaded click &apos;Create Slide&lsquo;.
                <h5>
                  File Upload Progress is:
                  {progress}
                  %
                </h5>
              </GridItem>
              <GridItem xs={12}>
                <input type="file" onChange={handleFileInput} />
                <button type="submit" onClick={() => uploadFile(selectedFile, 'discussion')}> Upload File </button>
              </GridItem>
            </GridContainer>
          </div>
          <div className={type === 'report' ? null : classes.hiddenInputBox}>
            <FormControl>
              <FormLabel>Report Type (required)</FormLabel>
              <RadioGroup
                aria-label="report type"
                value={reportType}
                onChange={(e) => {
                  setReportType(e.target.value);
                  // validate();
                }}
                required
              >
                <FormControlLabel value="wop" control={<Radio />} label="Walk Of Privilege" />
                <FormControlLabel value="word-cloud" control={<Radio />} label="Word Cloud" />
                <FormControlLabel value="comfort-zone" control={<Radio />} label="Comfort Zone" />
                <FormControlLabel value="poll" control={<Radio />} label="Poll" />
              </RadioGroup>
            </FormControl>
          </div>
          <div className={type === 'closing' ? null : classes.hiddenInputBox}>
            <h4 className={classes.infoText}>Create the text for the closing slide</h4>
            <CKEditor
              editor={ClassicEditor}
              data={generalText}
              onChange={(event, editor) => {
                setGeneralText(editor.getData());
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleModal()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSubmit()}>
            Create Slide
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

NewSlideModal.propTypes = {
  open: PropTypes.bool.isRequired,
  type: PropTypes.string,
  toggleModal: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object,
};

export default NewSlideModal;
