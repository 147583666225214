import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import PropTypes from 'prop-types';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';

import Button from '@material-ui/core/Button';
import Card from '../../../../components/Card/Card';
import CardBody from '../../../../components/Card/CardBody';
import CardHeader from '../../../../components/Card/CardHeader';
import styles from '../../../../assets/jss/material-dashboard-pro-react/views/loginPageStyle';
import CardFooter from '../../../../components/Card/CardFooter';

import MultiSelect from './MultiSelect';
import SingleSelect from './SingleSelect';
import PollCompleted from './PollCompleted';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    // paddingBottom: `env(safe-area-inset-bottom)`,
    overflow: 'auto',
    paddingBottom: `calc(
      20vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0)
    )`,
  },
  contentRoot: {
    // backgroundColor: 'lightGrey',
    // background: '#e1eaee61',
    justifyContent: 'space-between'
  },
  counter: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '25px',
  },
  copyButton: {
    margin: '25px 0px 7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '3px',
    height: '47px',
    backgroundColor: '#60A681',
    cursor: 'pointer',
    padding: '10px 25px',
  },
  ...styles,
});


const Poll = (props) => {
  const {
    sessionId,
    moduleTitle,
    questions,
    moduleId,
    isHosted,
    nextModule,
    pollScript
  } = props;

  const classes = useStyles();
  function getModalStyle() {
 
    return {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      backgroundColor: 'white',
      border: '1px solid #000',
      borderRadius:'25px',
      boxShadow: 24,
      p: 4,
      overflow: 'auto',
      maxHeight: '80vh',
      padding: '15px',
    };
  }

  const [completed, setCompleted] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [buttonText, setButtonText] = useState('NEXT');
  const [userAnswers, setUserAnswers] = useState([]);
  const [currentQNotAnswered, setCurrentQNotAnswered] = useState(true);
  const [currentUserId, setCurrentUserId] = useState(1);
  const [openTranslation, setOpenTranslation] = useState(false);
  const [modalStyle] = useState(getModalStyle);


  const handleOpen = () => {
    setOpenTranslation(true);
  };

  const handleClose = () => {
    setOpenTranslation(false);
  };

  
  useEffect(() => {
    setSelectedQuestion(questions[0]);
    setCompleted(window.localStorage.getItem('poll_completed'));
    setCurrentUserId(Number(window.localStorage.getItem('audience_user_id')));
  }, []);

  const handleButtonClick = async () => {
    // const selectedIndex = questions.map((o) => o.question).indexOf(selectedQuestion.question);
    if (questions.length > currentIndex + 1) {
      setSelectedQuestion(questions[currentIndex + 1]);
      setCurrentIndex(currentIndex + 1);
    }
    setCurrentQNotAnswered(true);

    if ((questions.length) === currentIndex + 1) {
      // const audienceUserId = Number(window.localStorage.getItem('audience_user_id'));
      // 2lines below were used for testing
      //   const delay = (ms) => new Promise((res) => setTimeout(res, ms));
      //   await delay(1000)

      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_EVENT_SERVICE}/poll-answer`,
        data: {
          audienceUserId: currentUserId > 1 ? currentUserId : 1,
          moduleId,
          sessionId,
          answers: userAnswers,
        },
      })
        .then(() => {
          setCompleted(true);
          window.localStorage.setItem('poll_completed', true);
        });
    }
  };

  useEffect(() => {
    if (questions.length === currentIndex + 1) {
      setButtonText('SUBMIT');
    }
  }, [currentIndex]);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div align="right">
        <CloseIcon className={classes.button} onClick={handleClose} />
      </div>
      <h3>{moduleTitle}</h3>
      <p id="simple-modal-description">
        {pollScript}
      </p>
    </div>
  );

  return (
    <>
      {(completed || false) ? (
        <PollCompleted
          nextModule={nextModule}
          isHosted={isHosted}
          moduleTitle={moduleTitle}
        />
      ) : (
        <div className={classes.root}>
          <Modal
            open={openTranslation}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
           {body}
          </Modal>
          <div className={classes.counter}>
            <h4>
              {currentIndex + 1}
              {' '}
              of
              {' '}
              {questions.length}
            </h4>
          </div>
          <Card login key={questions.length} style={{ marginBottom: '10px' }}>
            <CardHeader
              className={`${classes.cardHeader}`}
              color="primary"
            >
              <h4 className={classes.cardTitle}>{selectedQuestion.question}</h4>
            </CardHeader>
            <CardBody>
              <CardContent className={classes.contentRoot}>
                <>
                  {selectedQuestion.type === 'multiSelect'
                    ? (
                      <div>
                        <MultiSelect
                          question={selectedQuestion}
                          setUserAnswer={setUserAnswers}
                          userAnswers={userAnswers}
                          setCurrentQNotAnswered={setCurrentQNotAnswered}
                        />
                      </div>
                    ) : '' }
                  {selectedQuestion.type === 'multipleChoice'
                    ? (
                      <div>
                        <div>
                          <SingleSelect
                            question={selectedQuestion}
                            setUserAnswer={setUserAnswers}
                            userAnswers={userAnswers}
                            setCurrentQNotAnswered={setCurrentQNotAnswered}
                          />
                        </div>
                      </div>
                    ) : '' }
                </>
              </CardContent>
            </CardBody>
          </Card>
          
          <CardActions className={classes.contentRoot}>
            <Button className={classes.copyButton} disabled={currentQNotAnswered} size="small" onClick={() => handleButtonClick()}>{buttonText}</Button>
            {pollScript && pollScript.length > 1? 
            <Button onClick={()=> handleOpen()}> Read Scenario</Button>
              : null}
          </CardActions>
        </div>
      )}
    </>
  );
};

Poll.propTypes = {
  sessionId: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  questions: PropTypes.array.isRequired,
  moduleTitle: PropTypes.string.isRequired,
  moduleId: PropTypes.number.isRequired,
  nextModule: PropTypes.func,
  isHosted: PropTypes.bool.isRequired,
};

export default Poll;
